import { ElementProps } from "../../../../types/common";

export const ArrowIcon = (props: ElementProps) => (
  <svg
    width={8}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 13 1 7l6-6"
      stroke="#7E929A"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
