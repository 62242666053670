export const SECTION_ID = {
  SCHOOL: "school",
  BOOTCAMPS: "bootcamps",
  EXPERTS: "experts",
  FEEDBACK: "feedback",
  MEDIA: "media",
  QUESTIONS: "questions",
};

export const ArrowSvgPath =
  "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z";

export const BREAKPOINTS = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1366,
  xxxl: 1440,
  xxxxl: 1680,
};
