import { memo } from "react";
import { Accordion } from "../../components/common/accordion/accordion";
import { SECTION_ID } from "../../constants/constants";
import { Questions } from "../../model/main-page-data";

interface Props {
  questions: Questions;
  pageType?: string;
}

export const QuestionsSection = memo<Props>(
  ({ questions, pageType = "mainSitePages" }) => {
    return (
      <section id={SECTION_ID.QUESTIONS} className="bg-black rounded-t-[30px]">
        <div
          className={`${
            pageType === "blog"
              ? "relative z-10 top-[-30px] pt-[60px]"
              : "pt-35"
          } max-w-[1150px] m-auto pb-16 pl-5 pr-5 lg:pl-20 lg:pr-20 text-center text-white lg:pb-51 lg:pt-[80px]`}
        >
          <h2 className="pb-20 lg:pb-28">{questions.title}</h2>
          <div className="m-auto">
            {questions.list.map((question, index) => (
              <Accordion theme="dark" key={index} {...question} index={index} />
            ))}
          </div>
        </div>
      </section>
    );
  }
);
