import { memo, useMemo } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css/core";
import { SlideExpert } from "./expert-slide/expert";
import { Expert } from "../../../model/expert";
import { ArrowSvgPath } from "../../../constants/constants";

interface Props {
  onSelect: (data: Expert) => void;
  theme?: "dark" | "light";
  experts: Expert[];
}

export const Slider = memo<Props>(({ theme = "light", experts, onSelect }) => {
  const options = useMemo(
    () => ({
      gap: "12px",
      arrowPath: ArrowSvgPath,
      rewindByDrag: true,
      speed: 600,
      perPage: 4,
      perMove: 4,
      lazyLoad: true,
      type: "loop",
      breakpoints: {
        768: {
          type: "loop",
          fixedWidth: "316px",
          perMove: 1,
          gap: "12px",
        },
        1024: {
          type: "loop",
          perPage: 2.5,
          perMove: 1,
          gap: "12px",
        },
        1280: {
          type: "loop",
          perPage: 3,
          perMove: 1,
        },
        1366: {
          type: "loop",
          perPage: 3,
          perMove: 1,
        },
        1440: {
          type: "loop",
          perPage: 4,
          perMove: 1,
          gap: "16px",
        },
      },
    }),
    []
  );

  return (
    <Splide
      options={options}
      className="splide-experts first:ml-5 lg:first:ml-20 lg:first:mr-10 lg:ml-0 w-auto pb-40 lg:pb-0"
    >
      {experts.map((expert) => (
        <SplideSlide key={expert.id}>
          <SlideExpert theme={theme} data={expert} onClick={onSelect} />
        </SplideSlide>
      ))}
    </Splide>
  );
});
