import { ElementProps } from "../../../../types/common";

export const ArrowIcon = (props: ElementProps) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.435.328 13.75.362l.034 10.316-2.164-.033.017-6.54-9.518 9.519L.506 12.01l9.551-9.552-6.589.034L3.435.328Z"
      fill="#22282B"
    />
  </svg>
);
