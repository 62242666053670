import { memo } from "react";
import TwitterLogo from "./assets/twitter.png";
import Image from "next/image";
import { CloseIcon } from "./assets/close.icon";
import { Expert } from "../../model/expert";
import { sanitizeImageUrl } from "../../utils/sanity.utils";
import { toClassName } from "../../utils/style.utils";

interface Props {
  data: Expert;
  onContactPopupSubmitted: () => void;
  theme?: keyof typeof themes;
  profileTitle: string;
}

const themes = {
  light: {
    background: "bg-day-base-base-100",
    text: "",
    company: "text-day-base-base-400",
    profileBackground: "bg-day-base-base-100",
  },
  dark: {
    background: "bg-night-base-base-100",
    text: "text-night-base-base-900",
    company: "text-night-base-base-400",
    profileBackground: "bg-night-base-base-100",
  },
};

export const ExpertPopUp = memo<Props>(
  ({ theme = "light", data, onContactPopupSubmitted, profileTitle }) => {
    const currentTheme = themes[theme];
    return (
      <div className="lg:ml-8 backdrop-blur-2xl overflow-y-auto lg:max-w-[1360px] m-auto fixed pt-5 pb-5 lg:pb-0 lg:pt-0 lg:absolute top-[0px] pl-5 pr-5 lg:pl-0 lg:pr-0 left-[0px] z-30 w-auto lg:h-auto lg:rounded-[12px] lg:top-[0px] md:grid md:grid-cols-[325px_minmax(400px,_1fr)] lg:grid-cols-[475px_minmax(500px,_1fr)] xl:grid-cols-[395px_minmax(500px,_1fr)] xxl:grid-cols-[425px_minmax(500px,_1fr)] xxxl:grid-cols-[325px_minmax(500px,_1fr)] h-full gap-[16px]">
        <div
          className={toClassName(
            "relative lg:block rounded-[20px] pl-6 pr-6 pt-6 pb-10",
            currentTheme.background
          )}
        >
          <div
            onClick={onContactPopupSubmitted}
            className="flex items-center justify-between pb-8 md:hidden"
          >
            <div className="md:hidden pt-4 pb-4 pl-8 pr-8 rounded-[38px] uppercase text-[16px] text-day-base-base-500 bg-day-base-base-200">
              {profileTitle}
            </div>
            <div className="bg-day-base-base-200 rounded-[16px] p-5">
              <CloseIcon />
            </div>
          </div>
          <img
            className="rounded-[15px] w-[300px] h-[300px] lg:w-[450px] lg:h-[450px] xl:w-[370px] xl:h-[370px] xxl:w-[400px] xxl:h-[400px] xxxl:w-[300px] xxxl:h-[300px]"
            src={sanitizeImageUrl(data.image.asset._ref)}
            alt="Expert photo"
          />
          <div className="flex grow justify-between justify-items-center pr-5 pl-6 lg:pl-10 lg:block lg:pr-0 lg:pl-4">
            <div className="grow">
              <p
                className={toClassName(
                  "pt-4 text-[20px] leading-[1.2] md:pt-8",
                  currentTheme.text
                )}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: data.name,
                }}
              />
              <p
                className={toClassName(
                  "text-[16px] pt-2 leading-[1.2]",
                  currentTheme.company
                )}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: data.company,
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={toClassName(
            "p-12 mt-6 md:mt-0 grow rounded-[20px] lg:p-10 flex flex-col",
            currentTheme.profileBackground
          )}
        >
          <div className="grow">
            <div className="flex justify-between items-center">
              <div className="hidden md:block pt-3 pb-3 pl-8 pr-8 rounded-[38px] uppercase text-[16px] text-white bg-day-base-base-200 lg:text-day-base-base-500">
                {profileTitle}
              </div>
              <div
                onClick={onContactPopupSubmitted}
                className="relative top-[-7px] hidden cursor-pointer md:block lg:static"
              >
                <div className="bg-day-base-base-200 rounded-[16px] p-4">
                  <CloseIcon />
                </div>
              </div>
            </div>
            <p
              className={toClassName(
                "lg:pt-10 text-[19px] lg:w-[80%]",
                currentTheme.text
              )}
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </div>

          {data.twitter && (
            <div className="pt-16">
              <a
                href={`https://twitter.com/${data.twitter}`}
                target="_blank"
                rel="noreferrer"
                className="cursor flex items-center"
              >
                <Image
                  width={32}
                  height={32}
                  src={TwitterLogo}
                  alt="Twitter logo"
                />
                <span className="text-night-base-base-400 text-[18px] leading-[132%] pl-4">
                  @{data.twitter}
                </span>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
);
