import { memo, useState, useRef, useEffect } from "react";
import { toClassName } from "../../../utils/style.utils";
import { ArrowIcon } from "./assets/arrow.icon";
import { useSpring, animated, easings } from "@react-spring/web";
import { useRouter } from "next/router";

interface Props {
  title: string;
  description: string;
  theme?: keyof typeof themes;
  index: number;
}

const themes = {
  light: {
    border: "border-t border-solid border-night-base-base-200",
    text: "text-day-base-base-900",
  },
  dark: {
    border: "border-b border-solid border-night-base-base-100",
    text: "text-night-base-base-500",
  },
};

export const Accordion = memo<Props>(
  ({ title, description, index, theme = "light" }) => {
    const { locale } = useRouter();
    const currentTheme = themes[theme];

    const [isShown, setIsShown] = useState<boolean>(false);
    const [defaultHeightOfBlock, setDefaultHeightOfBlock] = useState<number>(0);
    const [defaultHeightOfTitle, setDefaultHeightOfTitle] = useState<number>(0);
    const contentRef = useRef<HTMLDivElement>(null);
    const answerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (contentRef.current) {
        setDefaultHeightOfBlock(contentRef.current.offsetHeight || 0);
      }
      if (answerRef.current) {
        setDefaultHeightOfTitle(answerRef.current.offsetHeight || 0);
      }
    }, [isShown, locale]);

    const { height } = useSpring({
      from: { height: defaultHeightOfTitle },
      to: {
        height: isShown ? defaultHeightOfBlock : defaultHeightOfTitle,
      },
      config: {
        duration: 250,
        easing: isShown ? easings.easeInCubic : easings.easeOutCubic,
      },
    });

    const { opacity } = useSpring({
      from: { opacity: 0 },
      to: {
        opacity: isShown ? 1 : 0,
      },
      config: {
        duration: 350,
        easing: isShown ? easings.easeInCubic : easings.easeOutCubic,
      },
    });

    const animatedHeight = height.interpolate((h) => `${h}px`);

    return (
      <animated.div
        style={{
          height: animatedHeight,
        }}
      >
        <div
          ref={contentRef}
          className={toClassName(
            "flex items-center justify-between pl-8 pr-8 lg:pl-16 lg:pr-16",
            currentTheme.border,
            index === 0 && theme === "light" && "border-t-0",
            index === 0 && theme === "dark" && "border-t"
          )}
          onClick={() => setIsShown((currentState) => !currentState)}
        >
          <div className="cursor-pointer w-full" ref={answerRef}>
            <div className="flex items-start justify-between pr-8 lg:pr-0 pt-8 pb-8 lg:pb-10 lg:pt-10 ">
              <div className="flex max-w-[85%] items-start lg:max-w-[80%] leading-[1.2] text-left lg:text-[28px]">
                {theme === "light" && (
                  <span className="mr-6 pl-6 pr-6 lg:pl-9 lg:pr-9 h-[28px] lg:h-[34px] text-day-base-base-500 text-[16px] bg-day-base-base-100 rounded-[8px] lg:rounded-[12px]">
                    <span className="relative top-[3px] lg:top-[7px]">
                      {String(index + 1).padStart(2, "0")}
                    </span>
                  </span>
                )}
                <div className="relative top-[2px] lg:top-[0px]">
                  <span
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                  <div>
                    {isShown && (
                      <animated.div
                        style={{
                          opacity,
                        }}
                      >
                        <p
                          className={toClassName(
                            "pb-10 text-left pt-8 lg:w-[95%] text-[15px] leading-[1.2] lg:text-[18px]",
                            currentTheme.text
                          )}
                          // eslint-disable-next-line
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      </animated.div>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-3 lg:pt-5">
                <div
                  className={
                    isShown
                      ? "rotate-90 transform transition-transform"
                      : "rotate-[-90deg] transition-transform"
                  }
                >
                  <ArrowIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    );
  }
);
