export function getCourseStatus(id: string): {
  isBlast: boolean;
  isOptimism: boolean;
  isFreeCourses: boolean;
  isPaidCourses: boolean;
} {
  const isBlast = id === "01ccf510-54d8-45aa-8c80-ad0b5a94c452__i18n_en";
  const isOptimism = id === "03b39b26-c950-45fd-81ba-74b1395e2d45__i18n_en";
  const isFreeCourses = isOptimism || isBlast;
  const isPaidCourses = !isFreeCourses;

  return { isBlast, isOptimism, isFreeCourses, isPaidCourses };
}
