import { memo } from "react";
import { TwitterFeedback } from "../../../model/twitter";
import { toClassName } from "../../../utils/style.utils";
import { sanitizeImageUrl } from "../../../utils/sanity.utils";

interface Props {
  data: TwitterFeedback;
  theme?: keyof typeof themes;
}

function withHighlightedLink(str: string): string {
  return str.replace(
    "@guidedao",
    `<span style="color:#1D9BEF;font-weight:600">@guidedao</span>`
  );
}

const themes = {
  light: {
    text: "text-black",
    subtitle: "text-day-base-base-400",
    background: "bg-day-base-base-100",
  },
  dark: {
    text: "text-white",
    subtitle: "text-night-base-base-400",
    background: "bg-night-background",
  },
};

export const Tweet = memo<Props>(({ theme = "light", data }) => {
  const currentTheme = themes[theme];

  return (
    <div
      className={toClassName(
        "mr-20 rounded-[12px] p-12",
        currentTheme.background
      )}
    >
      <div className="flex justify-between">
        <div className="flex">
          <div className="shrink-0">
            <img
              alt="Twitter photo"
              width={48}
              height={48}
              className="rounded-full"
              src={data?.image.asset && sanitizeImageUrl(data.image.asset._ref)}
            />
          </div>
          <div>
            <div className="ml-5 flex flex-col">
              <div
                className={toClassName(
                  "text-left text-[20px] font-bold leading-[1.2]",
                  currentTheme.text
                )}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: data.name,
                }}
              />
              <div
                className={toClassName(
                  "text-left text-day-base-base-400 text-[15px] leading-[1.2]",
                  currentTheme.text
                )}
              >
                @{data.nickname}
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-[24px] w-[24px] justify-items-end">
          <svg viewBox="0 0 24 24" aria-hidden="true">
            <g>
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
            </g>
          </svg>
        </div>
      </div>
      <div
        className={toClassName(
          "pt-6 text-left text-[18px] leading-[132%]",
          currentTheme.text
        )}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: `${withHighlightedLink(data.review)}`,
        }}
      ></div>
    </div>
  );
});
