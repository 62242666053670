import { memo } from "react";
import { Expert } from "../../../../model/expert";
import { toClassName } from "../../../../utils/style.utils";
import { sanitizeImageUrl } from "../../../../utils/sanity.utils";

const themes = {
  light: {
    name: "text-day-base-base-900",
    company: "text-day-base-base-400",
    hover:
      "bg-day-base-base-100 xl:bg-transparent xl:bg-none xl:hover:bg-day-base-base-100",
  },
  dark: {
    name: "text-white",
    company: "text-night-base-base-400",
    hover:
      "bg-night-background xl:bg-transparent xl:bg-none xl:hover:bg-night-background",
  },
};

interface Props {
  data: Expert;
  onClick: (data: Expert) => void;
  theme?: keyof typeof themes;
}

export const SlideExpert = memo<Props>(({ theme = "light", data, onClick }) => {
  const currentTheme = themes[theme];
  return (
    <div
      className={toClassName(
        "cursor-pointer rounded-[20px] pl-4 pr-4 pt-4 pb-8 lg:pl-6 lg:pt-6 lg:pr-6 lg:pb-10 xl:pb-10 transition-colors",
        `${currentTheme.hover}`
      )}
      onClick={() => onClick(data)}
    >
      <div className="rounded-[15px] min-h-[303px]">
        {data.image && (
          <img
            className="rounded-[15px] object-fill w-full h-full"
            src={sanitizeImageUrl(data.image.asset._ref)}
            alt={`Photo of ${data.name}`}
          />
        )}
      </div>
      <div className="pl-4 pr-4">
        <p
          className={toClassName(
            "pt-4 text-[18px] lg:pt-8 lg:text-[20px] leading-[1.2]",
            currentTheme.name
          )}
        >
          {data.name}
        </p>

        {data.company && (
          <p
            className={toClassName(
              "text-[16px] leading-[1.2] pt-2",
              currentTheme.company
            )}
          >
            {data.company}
          </p>
        )}
      </div>
    </div>
  );
});
