import { memo, useCallback, useState } from "react";
import { Experts, Expert } from "../../model/expert";
import { Slider } from "./slider/slider";
import { ExpertPopUp } from "./expert-popup";
import { SECTION_ID } from "../../constants/constants";
import { toClassName } from "../../utils/style.utils";

interface Props {
  theme?: keyof typeof themes;
  experts: Experts;
  profileTitle: string;
}

const themes = {
  light: {
    text: "text-day-base-base-900",
    title: "text-day-base-base-500",
    background: "bg-white",
    backgroundInner: "",
  },
  dark: {
    text: "text-white",
    title: "text-night-base-base-400",
    background: "bg-black",
    backgroundInner: "bg-black",
  },
};

export const ExpertsSection = memo<Props>(
  ({ theme = "light", experts, profileTitle }) => {
    const currentTheme = themes[theme];
    const [selectedExpert, setSelectedExpert] = useState<Expert | undefined>();

    const onContactPopupSubmitted = useCallback(() => {
      setSelectedExpert(undefined);
    }, []);

    return (
      <section
        id={SECTION_ID.EXPERTS}
        className={toClassName(
          "lg:pr-0 lg:pl-0 xl:mr-10",
          currentTheme.background
        )}
      >
        <div
          className={toClassName(
            "rounded-[20px]",
            currentTheme.backgroundInner
          )}
        >
          <p
            className={toClassName(
              "text-center text-[16px] leading-[130%] lg:text-[20px] lg:leading-[124%]",
              currentTheme.title
            )}
          >
            {experts.title}
          </p>
          <h3
            className={toClassName(
              "m-auto max-w-[900px] pt-6 lg:pt-8 lg:pt-8 pb-20 pl-10 pr-10 text-center md:pl-10 md:pr-10 xl:pl-0 xl:pr-0 lg:pb-32",
              currentTheme.text
            )}
          >
            {experts.subtitle}
          </h3>
          <div className="relative max-w-[1360px] m-auto lg:pb-70">
            <div className={selectedExpert ? "opacity-0" : "w-auto"}>
              <Slider
                experts={experts.items}
                theme={theme}
                onSelect={setSelectedExpert}
              />
            </div>
            {selectedExpert && (
              <ExpertPopUp
                profileTitle={profileTitle}
                theme={theme}
                data={selectedExpert}
                onContactPopupSubmitted={onContactPopupSubmitted}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
);
