import { memo, useMemo } from "react";
import { Tweet } from "./tweet/tweet";
import { Splide, SplideSlide, Options } from "@splidejs/react-splide";
import { toClassName } from "../../utils/style.utils";
import { TwitterFeedback } from "../../model/twitter";

import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

interface Props {
  theme?: keyof typeof themes;
  reviews: TwitterFeedback[];
}

const themes = {
  light: {
    background: "bg-white",
  },
  dark: {
    background: "bg-black",
  },
};

export const TwitterFeedBackSection = memo<Props>(
  ({ theme = "light", reviews }) => {
    const currentTheme = themes[theme];

    // It's used
    // noinspection JSUnusedGlobalSymbols
    const extensions = useMemo(() => ({ AutoScroll }), []);

    const options = useMemo(
      () =>
        ({
          fixedWidth: "540px",
          type: "loop",
          breakpoints: {
            1023: { fixedWidth: "400px" },
          },
          autoScroll: {
            type: "loop",
            drag: "free",
            focus: "center",
            perPage: 3,
            autoScroll: {
              speed: 1,
            },
          },
        } as Options),
      []
    );

    return (
      <section
        id="feedback"
        className={toClassName(
          "pt-30 lg:pt-46 pt-24 pb-24 lg:pb-48 text-center",
          currentTheme.background
        )}
      >
        <Splide
          extensions={extensions}
          options={options}
          aria-label="Twitter review"
          className="twitter-feedback"
        >
          {reviews.map((review) => (
            <SplideSlide key={review.id}>
              <Tweet theme={theme} data={review} />
            </SplideSlide>
          ))}
        </Splide>
      </section>
    );
  }
);
