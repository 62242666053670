import { memo, useState, useCallback } from "react";
import { Button } from "../../components/common/button/button";
import { getCurrentYear } from "../../utils/date.utils";
import { PopUpBlock } from "../../components/common/pop-up/pop-up";
import { Copyright } from "../../model/main-page-data";
import { FormCmsData } from "../../model/form";

interface Props {
  data: Copyright;
  forms: FormCmsData;
  orTitle: string;
}

export const CopyrightSection = memo<Props>(({ data, forms, orTitle }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const onContactPopupSubmitted = useCallback(() => {
    setIsShown(false);
  }, []);

  return (
    <section className="pt-55 pb-26 pt-50 w-full leading-none text-white lg:mt-0">
      <div className={isShown ? "block cursor-pointer" : "hidden"}>
        <PopUpBlock
          component="copyright"
          formId={"Попап с копирайта"}
          orTitle={orTitle}
          forms={forms}
          onContactPopupSubmitted={onContactPopupSubmitted}
        />
      </div>
      <div className="pl-5 pr-5 pb-10 lg:pl-20 lg:pr-20 pb-20 text-center text-[56px] leading-[86%] uppercase lg:text-[136px]">
        {getCurrentYear()}©<br /> {data.title}
        <br />
      </div>
      <div className="mb-80 flex flex-col items-center justify-center md:flex-row pl-5 pr-5">
        <a
          href="#bootcamps"
          className="block transition-colors hover:bg-night-base-base-100 lg:h-[56px] text-center w-full lg:w-max rounded-[16px] pl-10 pr-10 pt-8 pb-8 lg:pb-8 lg:pt-8 lg:pl-20 lg:pr-20 bg-transparent text-white border border-solid border-white cursor-pointer lg:w-max cursor-pointer mb-6 md:mr-5 md:mb-0"
        >
          <span // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: data.firstButton,
            }}
            className="lg:relative top-[1px]"
          ></span>
        </a>

        <Button
          className="cursor-pointer lg:w-max"
          fluid
          size="l"
          theme="accent"
          onClick={() => setIsShown(true)}
        >
          <span
            className="relative top-[-1px]"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: data.secondButton,
            }}
          ></span>
        </Button>
      </div>
    </section>
  );
});
