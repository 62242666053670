import { memo } from "react";
import { CardWithScreenshot } from "./card/card";
import { Screenshot } from "../../model/screenshot";

interface Props {
  screenshots: Screenshot;
}

export const ScreenshotSection = memo<Props>(({ screenshots }) => {
  return (
    <section className="max-w-[1440px] m-auto" id="learning-inner">
      <h2
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: screenshots.title,
        }}
        className="text-center text-day-base-base-900"
      />
      <p
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: screenshots.subtitle,
        }}
        className="m-auto max-w-[550px] pt-6 text-center text-[16px] lg:text-[18px] leading-[124%] text-day-base-base-400 lg:pt-8"
      />
      <div className="md:grid md:grid-cols-2 xl:grid-cols-3 md:gap-[10px] xl:gap-[16px] pt-16 lg:pt-22">
        {screenshots.items.map((data, index) => (
          <CardWithScreenshot key={index} data={data} />
        ))}
      </div>
    </section>
  );
});
