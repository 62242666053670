import { memo } from "react";
import { ArrowIcon } from "./assets/arrow.icon";
import { Card } from "../../../model/screenshot";
import { sanitizeImageUrl } from "../../../utils/sanity.utils";

interface Props {
  data: Card;
}

export const CardWithScreenshot = memo<Props>(({ data }) => {
  return (
    <a href={data.url} target="_blank" rel="noreferrer">
      <div className="flex h-full group flex-col cursor-pointer transition-colors bg-day-base-base-100 xl:bg-white xl:hover:bg-day-base-base-100 pr-4 pl-4 pt-4 lg:pt-6 lg:pr-6 pb-10 lg:pl-6 rounded-[20px] mb-10 md:mb-0">
        <div>
          <img
            src={sanitizeImageUrl(data.image.asset._ref)}
            alt={data.name}
            className="w-full rounded-[16px]"
          />
          <div className="pt-8 lg:pt-12 flex justify-between items-end pl-4 pr-4">
            <div className="flex-grow h-full flex-col text-left">
              <h4
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
                className="h4 text-day-base-base-900 text-[20px] xl:text-[21px] xxl:text-[26px] lg:leading-[110%]"
              ></h4>
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: data.name,
                }}
                className="xxl:block pt-3 xl:pt-6 mt-auto xl:mt-0 leading-[22px] text-[16px] xl:leading-[124%] text-day-base-base-400"
              ></div>
            </div>
            <div className="ml-5 flex-shrink-0 xl:opacity-0 transition-opacity xl:group-hover:opacity-100 h-[40px] w-[40px] lg:h-[48px] lg:w-[48px] bg-day-base-base-200 flex rounded-[10px] lg:rounded-[14px] items-center justify-center">
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
});
